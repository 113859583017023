html {
  font-size: 100%;
}

body {
  margin:0;
  padding:0;
  width:100%;
  @include rem("font-size", $regularSize);
  // font-family:$helvetica;
  font-family: 'fontTitle1';
  line-height:1.3;
}

ul {
  list-style:none;
  margin:0;
  padding:0;
}

ul li {
  margin:0;
  padding:0;
}

ol {
  margin:0;
  padding:0;
}
p {
  padding:0;
  @include rem("margin", 0, 0, 5, 0);
}

h1, h2, h3, h4, h5, h6 {
  padding:0;
  @include rem("margin", 0, 0, 10, 0);
  font-weight: normal;
}

fieldset {
  border:none;
  margin:0;
  padding:0;
}

button {
  cursor:pointer;
}

%clearfix {
  @include clearfix();
}

table, th, td {
  font-family: 'fontTitle1';

}
th {
  font-family: 'fontTitle1';
  font-size: 16px;
  font-weight: bold;

}
th {
  font-family: 'fontTitle1';
  font-size: 16px;

}