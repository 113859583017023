.station-history.stations-block {
    overflow-y: scroll;
    min-width: 250px;
    display: block;

    .page-station-history-station-block {
        position: relative;
        // margin-top: 40px;

        // .filter-block {
        //     position: absolute;
        //     top: -40px;
        //     width: 100%;
        // }

        .each-station {
            font-family: 'FontTitle1';
            font-size: 1.5em;
            background-color: #ddd;
            padding: 5px 15px;
            border-bottom: 1px solid #000;
            cursor: pointer;
    
            &:hover,
            &.active {
                background-color: darken(#ddd, 10%);
            }
    
            .title {
                font-weight: bold;
            }
            .sub-title {
    
            }
        }
    }
}

.page-station-history {
    display: flex;
    flex-grow: 1;
    position: relative;

    button.select-station {
        position: fixed;
        border: 0px;
        // padding: 5px;
        
        font-family: 'FontTitle1';
        font-size: 1.5em;
        background-color: $blue1;
        color: #fff;

        top: 60px;

        // left: 0;
        // border-top-right-radius: 5px;
        // border-bottom-right-radius: 5px;
        // padding-right: 10px;

        right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding-left: 10px;
    }

    .MuiButton-outlined {
        margin-bottom: 1em;
    }

    @media screen and (max-width: 767px) {
        .MuiButton-outlined {
            padding: 5px 6px !important;
        }
    }    
    
    .stations-info-block {
        width:100%;
        padding: 15px;

        .filter-by-days {
            text-align: right;
            span {
                font-family: 'FontTitle1';
                font-size: 1.25em;
            }
        }

        .recharts-responsive-container {
            font-family: 'FontTitle1';
            font-size: 1.25em;

            .custom-tooltip {
                background : #fff;
                padding: 0.5em;
            }
        }

        .station-title {
            font-family: 'FontTitle1';
            color: $blue1;
            border-bottom: 1px solid $blue1;
            margin-bottom: 1em;
    
            .title {
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 1em;
            }
            .sub-title {
                font-size: 1.5rem;
                line-height: 1em;
            }
        }
    }
}
