.notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.5em;

    .title {
        font-family: 'FontTitle1';
        font-size: 1.5em;
        font-weight: bold;
        margin-right: 0.5em;
        min-width: 120px;
        text-align: center;
        
    }
    .description {
        font-family: 'FontTitle1';
        font-size: 1.5em;
    }

    @media screen and (max-width: 767px) {
        .title {
            flex-grow: 5;
            flex-basis: 0;
        }
        .description {
            flex-grow: 8;
            flex-basis: 0;
        }
    }
}

.level-1 {
    background-color: $green;
    color: #fff;
}
.level-2 {
    background-color: $yellow;
    color: $black;
}
.level-3 {
    background-color: $orange;
    color: #fff;
}
.level-4 {
    background-color: $red;
    color: #fff;
}