@import './notification';

.content1 {
    font-size: 0.875em;

    @media screen and (max-width: 767px) {
        padding: 0.5em !important;
    }

    > div {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    
    .wrap-before-map {
        margin-bottom: 0.5em;

        .select-district {

        }

        .notification {

        }
    }

    @import './map/map';
}