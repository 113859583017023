.Stepper {
    position: relative;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.Stepper__step {
    position: relative;
    display: table-cell;
    text-align: center;
    padding: 0.5rem;
}

.Stepper__indicator {
    position: relative;
    display: block;
    z-index: 2;
}

.Stepper__label {
    position: relative;
    display: block;
    margin: 0.5rem 0;
    color: #cfd7de;
    z-index: 2;
    font-weight: bold;
    font-size: 1.5em;
    font-family: 'fontTitle1';
}

.Stepper__info {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 1px solid #e3e8ec;
    border-radius: 50%;
    background-color: #e3e8ec;
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    z-index: 2;
}

.Stepper__panel {
    // display: none;
}

.Stepper .Stepper__step {
    &:after {
        content: " ";
        position: absolute;
        left: 50%;
        top: 1.45rem;
        width: 100%;
        height: 0.125rem;
        background-color: #e3e8ec;
        z-index: 1;
    }

    &:last-child:after {
        display: none;
    }
}

.Stepper--inline {
    .Stepper__indicator {
        display: inline-block;
        vertical-align: inherit;
    }

    .Stepper__label {
        display: inline-block;
        vertical-align: inherit;
        text-align: left;
        padding: 0 0.5rem;
        background: #fff;
    }

    &.Stepper--bottom {
        .Stepper__label {
            vertical-align: middle;
        }

        .Stepper__step:after {
            top: auto;
            bottom: 1.45rem;
        }
    }
}

.Stepper--vertical {
    .Stepper__step {
        display: block;
        text-align: left;
    }

    .Stepper__label {
        padding-left: 1rem;
    }

    .Stepper__indicator, .Stepper__label {
        display: table-cell;
        vertical-align: middle;
    }

    .Stepper__panel {
        margin-left: 3.5rem;
    }

    .Stepper__step:after {
        content: " ";
        position: absolute;
        left: 1.45rem;
        top: 2.5rem;
        bottom: -0.5rem;
        width: 0;
        height: auto;
        border-left: 0.125rem solid #e3e8ec;
    }
}

.Stepper .Stepper__step {
    &.is-complete {
        .Stepper__info {
            border-color: #a6b6c3;
            background-color: #a6b6c3;
        }

        .Stepper__label {
            color: #a6b6c3;
        }
    }

    &.is-active {
        .Stepper__info {
            border-color: $blue1;
            background-color: $blue1;
        }

        .Stepper__label {
            color: $blue1;
        }

        &:after {
            border-color: $blue1;
        }
    }

    &.is-warning {
        .Stepper__info {
            border-color: #f1c40f;
            background-color: #f1c40f;
        }

        .Stepper__label {
            color: #f1c40f;
        }
    }

    &.is-error {
        .Stepper__info {
            border-color: #e95a4b;
            background-color: #e95a4b;
        }

        .Stepper__label {
            color: #e95a4b;
        }
    }
}