.wrap-main-map-information {
    table {
        &.table {
            td, th {
                padding: 0.25em;
            }
        }
    }

    table.top-water-data {
        font-family: 'FontTitle1';
        font-size: 1rem;
    }
}

.gm-style .gm-style-iw-t {
    top: -27px;
}