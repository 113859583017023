.map-popup {
    font-family: 'fontTitle1';
    font-size: 1.25em;


    .title {
        font-family: 'fontTitle1';
        font-weight: bold;
        font-size: 1.25em;
    }

    .custom-tooltip {
        background : #fff;
        padding: 0.5em;
    }
}