.wrap-weathers {
    .each {
        display: flex;
        // flex-direction: column;
        padding: 0.25em 0em;
        border-bottom: 1px solid #ddd;

        font-family: 'FontTitle1';  
        font-size: 1rem;

        .key {
            flex-basis: 0;
            flex-grow: 2; 
        }
        .value {
            flex-basis: 0;
            flex-grow: 1;
        }
        .unit {
            flex-basis: 0;
            flex-grow: 1; 
        }
    }
}