.button-popup {
    border: none;
    background-color: rgba(0,0,0,0);
}

button:focus {
    outline: none;
}

a {
    &:hover {
        text-decoration: none;
    }
}

button.no-style {
    border: none;
    background: none;
}