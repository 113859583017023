.page-radar {
	.wrap-each {
		width: 70%;
		margin: 0 auto;
		float: left;
		margin-bottom: 1em;

		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 0.5em;
		}
	}
}