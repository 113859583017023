// File Name: _footer.scss
// Description: global footer styles
// Used by: Page.scss
// Dependencies:
// ------------------------------------------------------------
footer {
    background-color: $dash_color_2 !important;
    color: $white !important;
    z-index: 9999;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 767px) {
        position: relative;
    }

    ul.menu {
        padding: 1em;
        padding-bottom: 0.5em;
        display: flex;
        flex-wrap: wrap;

        li {
            display: inline-block;
            margin-right: 0.5em;
            margin-bottom: 0.5em;
            flex-basis:0;
            flex-grow: 1;
            display: flex;
            flex: 1 1 120px;

            form {
                display: flex;
                flex-grow: 1;
            }

            input[type=submit] {
                display: block !important;
            }

            a,
            input[type=submit] {
                font-family: 'fontTitle1';
                line-height: 1em;
                font-size: 1.25em;
                font-weight: bold;

                padding: 0.25em;
                display: block;
                background: #eee;
                text-decoration: none;
                flex-grow: 1;
                flex-basis: 0;
                display: flex;
                align-items: center;
                text-align: center;
                color: $blue1;
                height: 40px;

                &:hover {
                    background: darken(#eee, 10%);
                }

                &:hover, &:focus, &:active, &:visited {
                    outline: none;
                }

                span {
                    width: 100%;
                }
            }
        }
    }
}