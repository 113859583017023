.content-style-1 {
    margin-bottom: 1em;
    
    .icon {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        // display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background: #eee;
    }
    .content {
        display: inline-block;
        vertical-align: middle;
        padding-left: 0.5rem;
        font-weight: bold;
    }

    &.is-active {
        .icon {
            background: $blue1;
        }
    }
}