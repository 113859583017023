*,
*:after,
*:before {
  @include box-sizing(border-box);
}
.row{
	@include rem("max-width", $maxWidth);
  @include clearfix();
	margin:0 auto;
}

.col{
  margin-left: 1.6%;
}

%col {
  float: left;
}

@for $i from 1 through $grid-columns {

  .col_#{$i} {
    @extend %col;
    width: ($i / $grid-columns) * 100%;
  }

}

.col:first-child,
.col_12 {
  margin-left: 0;
}

// Start my cmddi
.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .header {

  }

  .main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-content: stretch;
    justify-content: center;
    // height: 0;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-bottom: 70px;
    overflow: hidden;

    .content1,
    .content2 {
      flex-basis: 0;
      padding: 1em;
      overflow: hidden;
      overflow-y: scroll;
    }

    .content1 {
      flex-grow: 9;
      // overflow-y: hidden;
    }
    .content2 {
      flex-grow: 3;
    }

    @media screen and (max-width: 1024px) {
      .content2 {
        flex-grow: 4;
      }
    }

    @media screen and (max-width: 896px) {
      .content2 {
        flex-grow: 6;
      }
    }

    @media screen and (max-width: 767px) {
      display: block;
      // flex-direction: column;
      height: auto;
      position: relative;
      margin-bottom: 0;
      
      .content1,
      .content2 {
        // flex-grow: 1;
      }
    }
  }
  
  .footer {
    
  }
}

img {
  width: 100%;
}