// File Name: _header.scss
// Description: global header styles
// Used by: Page.scss
// Dependencies:
// ------------------------------------------------------------
header {
    background-color: #fff !important;
    box-shadow: 0 2px 1px rgba(0,0,0,0.08) !important;
    // color: $white !important;

    > div {
        min-height: 50px !important;
    }

    .warp-change-language {
        position: absolute;
        right: 10px;

        button {
            cursor: pointer;
            opacity: 0.5;

            &.active {
                opacity: 1;
            }
            
            img {
                width: 30px;
            }
        }
    }
}