// ----------------------------------------------------------------------
	// Layout Breakpoints
// ----------------------------------------------------------------------
$maxWidth: 1280;


// ----------------------------------------------------------------------
	// Font Sizes
// ----------------------------------------------------------------------
$regularSize: 16;


// ----------------------------------------------------------------------
	// Font Families
// ----------------------------------------------------------------------
$helvetica: Helvetica, Arial, sans-serif;

// ----------------------------------------------------------------------
  // Grid
// ----------------------------------------------------------------------
$grid-columns: 12;

$red: #f7464a;
$orange: #fdb55c;
$yellow: #f9ec7d;
$green:  #7ba38a;

// $red: #E95D4E;
// $orange: #F29037;
// $yellow: #E6BD7D;
// $green:  #7CA28B;
$white: #fff;
$blue: #65b6f7;
$blue1: darken($blue, 30);
$black: rgb(33, 37, 41);

.bg-red {
    background-color: $red;
}
.bg-orange {
    background-color: $orange;
}
.bg-yellow {
    background-color: $yellow;
}
.bg-green {
    background-color: $green;
}
.white {
    color: $white;
}
.red {
    color: $red;
}
.orange {
    color: $orange;
}
.yellow {
    color: $yellow;
}
.green {
    color: $green;
}

// ---- DASHBOARD COLOR
$dash_color_1: #F1F1F1;
$dash_color_2: #202020;
$dash_color_3: #7E909A;
$dash_color_4: #1C4E80;
$dash_color_5: #A5D8DD;
$dash_color_6: #EA6A47;
$dash_color_7: #0091D5;