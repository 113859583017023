.main-title-page {
    font-family: 'fontTitle1';
    font-weight: bold;
    color: $blue1;
    border-bottom: 1px solid $blue1;
    margin-bottom: 0.5em;
    line-height: 1.75em;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'fontTitle1';
    font-weight: bold;
}