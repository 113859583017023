@import './popup';

.wrap-main-map {
        
    height: 100%;

    .wrap-map-station-options {
        font-family: 'FontTitle1';
        font-size: 1.5em;
        line-height: 1.125em;
    }

    @media screen and (max-width: 767px) {
        display: block;
        // margin-left: -1em;
        // margin-right: -1em;

        .main-map {
            // padding: 0;
        }

        .loadingElement {
            height: 400px !important;
            width: 100vw;
        }
        
        .containerElement {
            height: 400px !important;
        }
        
        .mapElement {
            height: 400px !important;
        }
    }

    .wrap-map-station-options {
        position: absolute;
        top: 63px;
        right: 10px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        padding: 0.5em;
    }
}
