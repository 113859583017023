.air-map {
    .tabs {
        margin-bottom: 1em;
        
        span {
            font-family: 'FontTitle1';
            font-size: 1.5em;
            font-weight: bold;
        }

        button {
            background: #eee;

            &.Mui-selected {
                background: darken(#eee, 10%);
            }
        }
    }
    .each-block {
        display: flex;

        .image {
            flex-basis: 0;
            flex-grow: 10;
        }
        .date {
            flex-basis: 0;
            flex-grow: 2;
            padding-left: 1em;
            font-family: 'FontTitle1';
            font-size: 1.25em;
            line-height: 1em;

            ul {
                li {
                    margin-bottom: 0.5em;
                }
            }

            button {
                width: 100%;
                display: block;
                background: #eee;
                padding: 0.5em;
                text-decoration: none;
                text-align: center;

                &:hover,
                &.active {
                    background: darken(#eee, 10%)
                }
            }
        }
    }
}