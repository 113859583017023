.content-style-2 {
    margin-bottom: 1em;
    font-family: 'FontTitle1';
    
    .icon {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        // display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background: #eee;
        color: #fff;
        background: $blue1;

        span {
            display: block;
            line-height: 0.8em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
    .content {
        display: inline-block;
        vertical-align: middle;
        padding-left: 0.5rem;
        // font-weight: bold;
        font-size: 1rem;
    }

    &.inverse {
        .icon {
            color: $blue1;
            background: #eee;
        }
    }
}