body {
    background: #F4F5F7;
    color: #3e4855;
    font-family: 'Ubuntu', sans-serif;
    // font-size: 14px;
    line-height: 1.42857143;
}

.hidden-mobile {
	@media screen and (max-width: 767px) {
		display: none !important;
	}
}
.hidden-desktop {
	@media screen and (min-width: 768px) {
		display: none !important;
	}
}

.MuiDrawer-paperAnchorDockedLeft {
	border: 0px !important;
}