@import './map1_information';

.content2 {
    
    font-size: 0.875em;

    > div {
        // background: #eee;
    }

    @media screen and (max-width: 767px) {
        padding: 0.5em !important;
    }

}

.content-box {
    background: #fff;
    margin-bottom: 20px;
    border-color: rgba(158,173,195,.16);
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}